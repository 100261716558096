<template>
  <li :class="clName">
    <div class="card" :id="property.id" @click.stop.prevent="goToProp()">
      <div class="frame">
        <div class="itm" :style="{ backgroundImage: card === 'card trans' ? 'url(' + property.image + ')' : ''}">
          <img :class="{ 'trans': card === 'card trans' }" class="img" v-if="card === 'card trans' || card === 'card seo' || card === 'card lodge'" :alt="property.name" :src="property.image"/>
          <div class="booking" v-if="property.laterooms">LateRooms.com</div>
          <div class="pet" v-show="property.pet_count > 0" title="This property allows pets">
            <div class="maxpet" v-show="isNumber(property.pet_count)">
              {{ property.pet_count }}
            </div>
            <svg class="ico--15">
              <use xlink:href="#icon-pet">
              </use>
            </svg>
          </div>
        </div>
      </div>
      <div class="detail">
        <div class="head">
          <div class="titles">
            <h3 class="name" :title="property.name"> {{ property.name }}</h3>
            <p class="region" v-if="property.location"> {{ property.location }}</p>
          </div>
          <div class="side">
            <div class="sleeps" v-if="property.sleeps">
              Sleeps {{ property.sleeps }}
            </div>
          </div>
        </div>
        <div class="foot ctr-ver">
          <p v-if="card === 'card seo'" class="view" @click.stop="newSearch">Start a new search</p>
        </div>
      </div>
    </div>
  </li>
</template>

<script>

export default {
    data: function () {
    return {
    }
  },
  props: {
      property: {
          type: Object,
          required: true
      },
      clName: String,
      card: String,
      date: String,
      nights: Number
  },
  methods: {
      goToProp: function () {
          let date = this.$props.date,
              nights = this.$props.nights;
          let newDate = st.reverseDate(date);
          let hasDate = this.hasVal(date),
              hasNights = this.hasVal(nights);
          let id = this.$props.property.id;

          let link = `${location.origin}/properties/${id}`

          let url = !hasDate && !hasNights ? link : link + "?"
            + (hasDate ? "check_in_on=" + newDate : "")
            + (hasDate && hasNights ? "&" : "")
            + (hasNights ? "nights=" + nights : "");

          this.$emit('track', "prop_click", id);

          if (document.body.classList.contains("isIE")) {
              window.location.href = url;
          } else {
              window.open(url, "_blank");
          }
      },
      hasVal(val) {
         return val !== "" && typeof val !== "undefined";
      },
      isNumber(value) {
        return typeof value === 'number';
      },
      isScrollFinished() {
          const isFinished = setInterval(() => {
              if (window.scrollY === 0) {
                  document.getElementById("date").click();
                  clearInterval(isFinished);
              }
          }, 25);
      },
      newSearch: function() {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
        this.isScrollFinished();
      }
  }
}
</script>


<style scoped lang="scss">
@import 'app/assets/stylesheets/_vars.scss';

.card {
  height: 6rem;
  display: flex;
  padding: 0.75rem;
  box-sizing: border-box;
  width: 100%;
  border-radius: 0.188rem;
  background: $col_offwhite;
  box-shadow: 0 .125rem .25rem #ccc;
  cursor: pointer;
  margin: auto;

  &:hover {
    box-shadow: 0 .125rem .875rem $col_aqua;
  }
}

.frame {
  height: auto;
  width: 35%;
  flex-shrink: 0;
  border-radius: 0.188rem;
  overflow: hidden;
  position: relative;
}

.itm { 
  min-height: 100%;
  background-size: cover;
  border-radius: 0.188rem;
}

.booking {
    position: absolute;
    bottom: 0;
    font-size: 0.688rem;
    font-weight: 700;
    line-height: 1.5rem;
    width: 100%;
    color: $col_white;
    background: #411585;
    text-align: center;
}

.img {
  opacity: 1;
  width: 100%;
}

.pet {
  position: absolute;
  background: $col_green;
  border-radius: 100%;
  top: .25rem;
  right: .35rem;
  height: 1.5rem;
  width: 1.5rem;

  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -.45rem 0 0 -.45rem;
    fill: $col_white;
  }
}

.maxpet {
  height: 1rem;
  width: 1rem;
  font-size: .688rem;
  line-height: 1rem;
  top: 1.35rem;
  margin-left: -.5rem;
  font-weight: 700;
  left: 50%;
  text-align: center;
  background: $col_white;
  position: absolute;
  border-radius: 100%;
  font-weight: 700;
  box-shadow: 0 .125rem .125rem $col_oplightblack;
}

.detail {
  padding: 0;
  padding-left: 0.75rem;
  display: flex;
  flex-direction: column;
  width: 65%;
  overflow: hidden;
}

.head {
  min-height: 0;
  display: flex;
  flex-direction: column;
}

.titles {
  width: 100%;
}

.name {
  font-size: 0.875rem;
}

.region {
  color: $col_black;
  text-transform: inherit;
  font-size:0.75rem;
  font-weight: inherit;
}

.side {
  display: flex;
  font-size: .75rem;
  padding-top: .25rem;
}

.sleeps {
  text-align: left;
  color: $col_deepgrey;
  display: flex;
  align-items: center;

  svg {
    width: 1.25rem;
    margin-right: .25rem;
  }
}

.name {
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.foot {
  flex-direction: row-reverse;
  align-items:end;
}

.view {
  font-size: .875rem;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: underline;
}
</style>