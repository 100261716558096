/* eslint no-console: 0 */

import History from '../history.vue'

document.addEventListener('DOMContentLoaded', () => {
  const el = document.querySelector("#history");
  const app = new Vue({
    el,
    render(h){
      return h(History, {
        props: {
            isHome: el.dataset.home === "true",
            isProp: el.dataset.prop === "true",
            colClass: el.dataset.col,
            thisWeekend: el.dataset.wknd
        }
      });
    }
  });
});
